.escalation-ticket-selector{
    .field{
        margin-top: 8px;
        margin-bottom: 8px;
        > div{
            text-align: left;
        }
    }
    .title{
        text-align: left;
    }
}
@primary-color: #096dd9;