.card-bar {
  .card {
    margin-top: 8px;

    .gantt-chart-container {
      height: 400px;
      overflow: auto;
      padding: 32px;
    }

    .status-log-container {
      padding: 8px 32px;
      height: 320px;
      overflow: auto;
    }
  }
}
@primary-color: #096dd9;