.service-approve-container {

}

.approve-operate-bar {
  float: right;
  .left-btn {
    margin-right: 8px;
  }
}
@primary-color: #096dd9;