.ticket-card-layout{
    display: flex;
    flex-direction: column;
    margin: 32px;
    padding: 16px;

    .ticket-card-up{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .ticket-left-bar{
            display: flex;
            flex-direction: row;
            > *{
                margin-right: 8px;
            }
        }
        .ticket-right-bar{
            display: flex;
            flex-direction: row-reverse;
            > *{
                margin-left: 8px;
            }
            .time-watch{
                color: red;
            }
            .switch{
                margin-top: 8px;
            }
        }
    }
    .ticket-card-down{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ticket-card-down-left{

            > * {
                margin-top: 4px;
                margin-bottom: 4px;
            }
            text-align: left;
            .ticket-subject{
                font-size: 24px;
                
            }
            .ticket-subtitle{
                font-size: 16px;
            }
        }
        .ticket-card-down-right{
            > *{
                font-weight: bold;
                font-size: 20px;
                margin-top: 8px;
                margin-bottom: 8px;
                width: 120px;
                text-align: right;
                border-top-left-radius:25px;
                border-bottom-left-radius:25px;
                padding-right: 4px;
                //background-image: linear-gradient(to right, red , yellow);
                background-color: #dfdfdf;
            }
            
        }
    }
    .other-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    box-shadow: 0px 0px 5px 0px #096dd9;
}

.ticket-card-search{
    margin: 32px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    .search-option{
        margin-right: 16px;
    }
}
.ticket-card-filter{
    margin-top: 16px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
}
@primary-color: #096dd9;