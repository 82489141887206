.privilege-container {
	padding: 16px;
	display: flex;
	flex-direction: column;

	.title {
		font-size: 24px;
	}

	.top-bar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	.user-global-button {
		display: flex;

		&>* {
			margin-right: 8px;
		}
	}
}


@primary-color: #096dd9;