.ant-layout-sider-children{
	display: flex;
	
}

.ant-layout-sider-trigger {
	position: relative !important;
}

.ant-layout-sider{
	display: flex;
	flex-direction: column;
}


.main-content {
	background-color: white;
	overflow-y: auto;
}

.ant-layout-sider-has-trigger{
	padding-bottom: 0px !important;
}

.ant-breadcrumb.breadcrumb {
	margin: 8px 0px;
}

//Fix too long menu sider not fit issue 
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed{
	width:80px;
}
@primary-color: #096dd9;