.subject-changed{
    .changed-item{
        display: flex;
        flex-direction: column;
        width: 50%;
        .before , .after{
            margin: 8px;
            background-color: #ebebeb;
            text-align: center;
        }
        .pointer {
            text-align: center;
        }

    }
    .changed-item-type{
        font-weight: bold;
        font-size: 16px;
    }
}
@primary-color: #096dd9;