.action-selector {
    display: flex;
    flex-direction: column;
    .title{
        text-align: left;
    }

    .action-selector-editor{
        text-align: left;
        .field{
            margin-top: 8px;
            margin-bottom: 8px;
            text-align: left;
        }
    }
    
}
@primary-color: #096dd9;