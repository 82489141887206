.hard-assign-selector {
    display: flex;
    flex-direction: row;

    > *{
        margin-left: 8px;
    }

    .selector {
        width: 300px;
        text-align: left;
    }

}
@primary-color: #096dd9;