.task-meta-list-container {}

.knowledge-list {
    .left-btn {
        margin-right: 8px;
    }

    .bottom-card {
        margin-top: 8px;
    }
}
@primary-color: #096dd9;