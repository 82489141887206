.ticket-meta-container{
	padding: 16px;
	display: flex;
	flex-direction: column;
}

.meta-preview{
	display: flex;
	flex-direction: row;
	& > div{
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
		flex-direction: column;
		.title{
			text-align: left;
			margin-bottom: 8px;
		}
	}
}
@primary-color: #096dd9;