.state-change-view {
	display: flex;
	flex-direction: row;
	margin-top: 4px;

	.item {
		padding: 8px;
		 background-color: #ebebeb;
	}
	.pointer{
		align-self: center;
		margin-left: 8px;
		margin-right: 8px;
	}
}
@primary-color: #096dd9;