.logo {
	width: 64px;
	background-color: #001529;
	height: 48px;
}

.ant-layout-header.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 48px;
	line-height:48px;
}

.status-bar{
	height: 100%;
	display: flex;
	flex-direction: row-reverse;
	.status-item {
		margin-left: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.outline-button{
		font-size: 20px;
		color: #08c;
		margin-top: 4px;
		
	}
}
.search-bar {
	width: 300px;
	padding-left: 8px;
	padding-right: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.menu {
	width: 100%;
}
.login-info-container{
	padding: 8px;
	width: 200px;
	> *{
		margin-top: 8px;
	}
	.item{
		display: flex;
		flex-direction: column;
	}

	.label {
		font-weight: bold;
	}
	.button{
		margin-top: 8px;
		text-align: right;
	}
}
@primary-color: #096dd9;