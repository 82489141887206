.dynamic-form-container {
	margin-top: 16px;
	margin-left: 8px;
}


.dynamic-form {
	display: flex;
	flex-direction: column;

	.dynamic-top-bar {
		display: flex;
		flex-direction: row;

		> * {
			margin-right: 16px;
		}

		.dynamic-title {
			font-size: 16px;
			font-weight: bold;
		}

		.dynamic-description {
			color: #6c6c6c;
		}

	}

	.dynamic-fields {
		width: 100%;
		border-left: #aec4fc 2px solid;
		display: flex;
		flex-flow: wrap;
		flex-direction: row;
		margin-right: 16px;

		.property-wrapper {
			margin-left: 16px;
			margin-right: 16px;

			.form-group {
				&.field {
					display: flex;
					flex-direction: column;
				}
			}

			&.width-percent-45 {
				width: 45%;
			}

			&.width-percent-100 {
				width: 100%;
			}

			.dynamic-field-label {
				text-align: left;
				padding-top: 8px;

				.required-star {
					margin-left: 8px;
					color: red;
				}
			}

			.dynamic-error-field {
				display: flex;
				flex-direction: column;

				&>* {
					text-align: left;
					color: red;
				}
			}
			.ant-input-number-input{
				background-color: white;
				color: black;
			}

			.ant-input-disabled{
				background-color: white;
				color: black;
			}
		}

		

		input {
			width: 100%;
		}
	}

}

.array-pannel .dynamic-form .dynamic-fields .property-wrapper {
	width: 100%;
}

.array-ops {
	display: flex;
	margin: 8px 0px;
}

.calculation-fields {
	display: flex;
	flex-direction: row;

	.calculation-field {
		width: 48%;
		display: flex;
		flex-direction: column;
		padding: 16px 0px;

		.label {
			text-align: left;
		}

		.value {
			padding: 0px 16px;
		}
	}

}
@primary-color: #096dd9;