
.editor-bar{
	display: flex;
	flex-direction: column;
	
	.operation-bar{
		margin-bottom: 16px;
		align-self:flex-start ;
	}
}


@primary-color: #096dd9;