.thread-header{
	display:flex;
	flex-direction: row;
	.user-avatar{
		height: auto;
		width: auto;
	}
	.content {
		display: flex;
		flex-direction: column;
		margin-left: 16px;
		align-items:flex-start;
		.abstract{

		}
		.thread-type {
			color:#9f9f9f;
			display: flex;
			flex-direction: row;
			& > * {
				margin-right: 8px;
			}
		}
	}
}

.thread-detail{
	display: flex;
	flex-direction: column;
	padding:8px;

}

.thread-content{
	padding: 16px;
	text-align: left;
}
@primary-color: #096dd9;