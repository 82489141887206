.my-ticket-page-container{
	padding: 16px;
	display: flex;
	flex-direction: column;
	.stats{
		display: flex;
		flex-direction: row;
		.stat-item{
			padding-left: 16px;
			min-width: 80px;
		}
	}
}


@primary-color: #096dd9;