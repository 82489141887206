.assign-to-view{
	display: flex;
	flex-direction: column;
	.field{
		display: flex;
		flex-direction: row;
		margin-top: 4px;
		margin-bottom: 4px;
		.selector{
			width: 200px;
		}
		.title{
			width: 100px;
			padding-top: 6px;
		}
	}

}
.ant-card-extra{
	& > .ant-btn {
		margin-left: 8px;
	}
}
@primary-color: #096dd9;