.meta{
    margin-bottom: 32px;
}
.template-def{
    > * {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
    }
    .tags-view{
        margin-top: 8px;
    }
}
@primary-color: #096dd9;