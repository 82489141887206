.mailbox-contact-binding {
    .operation-bar {
        margin-bottom: 8px;

        >* {
            margin-left: 8px;
        }
    }
}

.mailbox-contact-binding-editor {

    .operation-bar {
        > * {
            margin-left: 8px;
        }
    }
    .bind-type{
        margin-top: 8px;
        margin-bottom: 8px;
    }
}
@primary-color: #096dd9;