.task-details-container {
  .operation-bar {
    .left-btn {
      margin-right: 8px;
    }
  }

  .task-details {
    margin-top: 8px;

    .status-log-container {
      height: 304px;
      overflow: auto;
      padding: 8px;
    }
  }
}
@primary-color: #096dd9;