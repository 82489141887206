.box-detail-view{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    
    .mailbox-detail{
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;

        .left-list {
            width: 20%;
        }
        .right-content-list {
            width: 80%;
            height: 100%;
    
            .ant-empty-image{
                height:100%
            }
        }
    }

    .mail-box-status-bar {
        height: 48px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        
        .left-controls{
            display: flex;
            flex-direction: row;
            width: 50%;
            
        }
        .right-controls{
            width: 50%;
            display: flex;
            flex-direction: row-reverse; 
            > * {
                margin-left: 8px;
            }
            align-items: baseline;
        }
    }


    
}
@primary-color: #096dd9;