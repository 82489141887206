.participated-summary-view{
    height: 100%;
    .total-summary{
        width: 100%;
        height: 160px;
        display: flex;
        flex-direction: row;
        .left-part{
            width: 33%;
        }
    }
    .participator-anaylsis-tab{
        height: 100%;
        width: 100%;
        padding: 16px;
    }

}
@primary-color: #096dd9;