.process-editor-container {
	display: flex;
	flex-direction: column;
	padding: 16px;

	.editor-header {
		padding-top: 8px;
		padding-bottom: 8px;
		text-align: left;
		padding-left: 8px;
	}

	.step-summary {
		padding: 0px 16px;
	}

	.editor-steps {
		padding: 0px 16px;
	}

	.editor-ref {
		.editor-form-ref {
			padding: 16px;
		}

		.editor-approval-ref {
			padding: 16px;
		}

		.editor-form-ref-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-content: center;
			border-bottom: 1px #dfdfdf solid;
			align-items: center;

			.ref-form-title {
				text-align: left;
				min-width: 250px;
				width: 100%;
			}

			.select {
				padding-top: 8px;
				margin-bottom: 8px;
			}

			.label {
				padding-top: 16px;
				align-self: center;
				margin-right: 8px;
				margin-left: 8px;
				margin-bottom: 8px;
				min-width: 32px;
			}

			.display-at {
				width: 100%;
				display: flex;
				flex-direction: row;
			}

			.editable-at {
				width: 100%;
				display: flex;
				flex-direction: row;
			}
		}

		.editor-data-ref-item {
			display: flex;
			flex-direction: row;

			.configure-item {
				margin: 0px 8px;
				&.label{
					padding-top: 4px;
				}
			}
		}
	}

	.stage-container {

		display: flex;
		flex-direction: row;
		align-content: space-between;
		width: 100%;

		.stage-right {
			margin-left: 16px;
			width: 100%;
			display: flex;
			flex-direction: column;

			.stage-topic {
				margin: 8px 0;
				font-size: large;
				font-weight: bold;
			}

			.stage-ref {
				padding: 8px 0;
				background-color: rgba(09, 109, 217, 0.1);
			}

			.stage-selection {
				margin: 8px 0px;
			}

		}

		.stage-left {
			align-self: center;
		}
	}

	.approval-buttons {
		&>* {
			margin-right: 8px;
		}
	}
}
@primary-color: #096dd9;