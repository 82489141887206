
.status-view{
	display: flex;
	flex-direction: column;
	.field{
		display: flex;
		flex-direction: row;
		margin-top: 8px;
		margin-bottom: 8px;		
		.title{
			width: 80px;
		}
		.ant-select{
			width: 150px;
		}
	}
}
@primary-color: #096dd9;