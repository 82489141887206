.mail-list-view {
    padding: 8px;
    display: flex;
    flex-direction: column;

    height: 100%;

    .mail-list-container{
        height: 100%;
        overflow-y: auto;
        margin-top: 8px;
    }

    .search-field{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .search-input{
            width: 100%;
            margin-right: 8px;
        }
    
    }

    .mail-item {
        height: 60px;
        text-align: left;
        border: 1px solid #dfdfdf;
        padding: 4px 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: default;

        &:hover {
            box-shadow: 0px 0px 5px 0px #096dd9;
        }

        .title-field {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight:bold;
            
        }
        .info{
            display: flex;
            flex-direction: row;
            font-weight: 200;
            .sender {
                width: 50%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .date {
                width: 50%;
                text-align: right;
            }

        }
    }
}
@primary-color: #096dd9;