.form-editor{
	display: flex;
	flex-direction: column;
	.form-editor-bar{
		display: flex;
		flex-direction: row;
		margin: 16px 0px;
		& > *{
			margin-right: 16px;
		}
	}
}
@primary-color: #096dd9;