.filter-container{
	display: flex;
	flex-direction: column;
	padding: 8px;
	& > * {
		margin-top: 8px;
	}
	.filter-header{

	}
	.filter-body{

	}
	.filter-footer{
		& > *{
			margin-right: 8px;
		}

	}
}


@primary-color: #096dd9;