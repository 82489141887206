.access-key {
  .important-msg {
    color: red;
  }
  .button-bar {
    display: flex;
    float: right;
    margin-bottom: 8px;
    &>* {
      margin-right: 8px;
    }
  }
}


@primary-color: #096dd9;