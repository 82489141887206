.subject-container{
    margin-left: 16px;
    cursor: pointer;
    color: #096dd9;


    :hover{
        color: #3A8AE0;
        font-weight: bold;
    }
}
@primary-color: #096dd9;