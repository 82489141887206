.new-ticket-editor-container{
	& > div {
		padding-left: 8px;
		padding-right: 8px;
	}

	.field{
		margin-top: 8px;
		margin-bottom: 8px;
		.field-lable{
			margin-bottom: 8px;
		}
	}
	.template-selector{
		background-color: #dfdfdf;
		padding: 8px;
		.fill-template{
			margin-top: 8px;
		}
	}

}

.user-active{
	color: #000;
}
.user-inactive{
	color: #dfdfdf;
}

.status-view-new-editor{
	display: flex;
	flex-direction: column;
	.field{
		display: flex;
		flex-direction: column;	
		.title{
			margin-bottom: 8px;
		}
		.ant-select{
			width: 100%;
		}
	}
}
@primary-color: #096dd9;