.ticket-axis-selector{
    display: flex;
    flex-direction: column;
    .axis-editor{
        .field{
            margin-top: 8px;
            margin-bottom: 8px;
            text-align: left;
        }
    }
    .title{
        text-align: left;
    }
}
@primary-color: #096dd9;