.pie-chart-summary-container {

    display: flex;
    flex-direction: column;
    height: 100%;

    .pie-panel {
        height: 200px;
    }

    .summary-panel {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        flex-shrink: 1;
        > * {
            margin-left: 4px;
            margin-top: 4px;
            width: 80px;
            height: 60px;

        }

    }


}
@primary-color: #096dd9;