.statistics-overview-container {
    display: flex;
    flex-direction: row;
    .statistics-row {
        display: flex;
        flex-direction: row;

        .row-item {
            margin: 8px;
            padding: 16px;
            .statistics-title{
                text-align: left;
                font-weight: bold;
                font-size: 16px;
            }
            border: 1px solid #dfdfdf;
            display: flex;
            flex-direction: column;
            
        }

        .row-item-33 {
            width: 33%;
        }

        .row-item-25 {
            width: 25%;
        }

        .row-item-50 {
            width: 50%;
        }

        .row-item-50 {
            width: 100%;
        }
    }
    .statistics-column-100 {
        width: 100%;
    }

    .statistics-column-75 {
        width: 75%;
    }

    .statistics-column-25 {
        width: 25%;
    }
    .statistics-column{
        display: flex;
        justify-content: center;
    }

}
@primary-color: #096dd9;