.process-editor{
	display: flex;
	flex-direction: column;
	margin: 16px;
	justify-content: space-between;
	height: 95%;
	.editor-title{
		text-align: left;
		font-size: 24px;
		font-weight: bold;
		padding: 8px;
		height: 36px;
	}
	.editor-step{
		margin: 16px;
	}
	.editor-step-detail{
		height: 100%;
		overflow: auto;

	}
	.editor-item{
		border-left: 1px #dfdfdf solid;
		padding-left: 8px;
	}

	.editor-step-control{
		height: 48px;
		text-align: left;
		margin-left: 16px;
		padding-top: 8px;
		> button {
			margin-right: 8px;
		}
	}
	.initial-button > * {
		margin-right: 8px;
	 }

	 .summary{
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		
	 }
	 .processCompleted{
		display: flex;
		flex-direction: row;
		font-size: medium;
		margin-top: 8px;
		& > * {
			margin-right: 16px;
		}

	 }
}
@primary-color: #096dd9;