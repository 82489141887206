.email-config-editor{
    display: flex;
    flex-direction: column;
    .item-label {
        text-align: right;
        margin-top: 6px;
    }
    > * {
        margin-bottom: 16px;
    }

    .required-mark {
        text-align: left;
        color: red;
        margin-top: 6px;
    }
    .button-field{
        display: flex;
        flex-direction: row-reverse;
        > * {
            margin-left: 16px;
        }
    }
}
@primary-color: #096dd9;