.template-editor {
    .operation-bar {
        display: flex;

        >* {
            margin-left: 8px;
        }
    }

    .quill-editor {
        overflow-y: auto;
        display: flex;
        width: 100%;
        height: 480px;
        flex-direction: column;

        .ql-container {
            min-height: 90%;
            height: auto;
            border: 0px;
        }

        .tool-bar {
            border-left: 0px;
            border-top: 0px;
            border-right: 0px;
        }

        // img {
        //     max-width: 60%;
        // }

        border: 1px #dfdfdf solid;
    }
}
@primary-color: #096dd9;