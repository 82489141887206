  .site-layout-background {
	background: #fff;
  }

.layout-content {
	padding: 0px 32px;
	display: flex;
	flex-direction: column;
}

.layout-root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: auto;
	height: 100%;

}





@primary-color: #096dd9;