.service-template-editor {
    margin: 8px;

    .detail-container {
        text-align: left;
    }

    .description {
        margin-right: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .label {
        text-align: left;
        font-weight: bold;
    }

    .service-order-container {
        border: solid 1px #dfdfdf;
        margin-top: 16px;
        margin-right: 16px;
        padding-bottom: 16px;

        .service-order-section {
            margin-top: 8px;
            margin-right: 8px;
            margin-left: 8px;

            .section-list {
                margin-top: 8px;
            }
        }

        .material {
            border: 1px solid #dfdfdf;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 8px 8px;
            padding: 8px;

        }

        .estimate {
            display: flex;
            flex-direction: row;
        }
    }

    .add-btn {
        border-color: #306cd2;
    }

    .left-btn {
        margin-right: 4px;
    }
}

.ant-page-header-footer {
    display: flex;
    justify-content: space-around;
}
@primary-color: #096dd9;