.detail-tab {
    width: 100%;
    padding: 16px;
    height: 100%;
    .ant-tabs-content {
        height: inherit;
    }

    .ant-tabs-content-holder{
        height: 100%;
        overflow-y: auto;
    }
}
@primary-color: #096dd9;