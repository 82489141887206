.meta-field-container {

	.sub-title {
		text-align: left;
		margin-bottom: 12px;
		//border-bottom: 1px #dfdfdf solid;
		display: flex;
		flex-direction: row;
	}

	.content {
		display: flex;
		flex-direction: row;

		.detail {
			width: auto;
		}

		.extra {
			margin-left: 16px;
			margin-right: 16px;

			.highline-bar {
				display: flex;
				width: max-content;
				justify-content: center;

				&>* {
					margin-left: 8px;
				}
			}
		}
	}

}

.ant-page-header-heading-sub-title {
	.ant-switch {
		background-color: hsl(120, 100%, 86%);
		background-image: linear-gradient(to right, rgba(0, 200, 0, 1), rgb(0, 150, 0)), linear-gradient(to right, #fff, #fff);
		&.ant-switch-checked{
			background-image: linear-gradient(to right, rgb(0, 90, 200), rgb(0, 90, 200)), linear-gradient(to right, #fff, #fff)
		}
	}
}
.ant-tabs{
	width: 100%;
}


.tab-thread {
	padding: 8px;
	overflow-x: auto;
	height: 100%;
}
@primary-color: #096dd9;