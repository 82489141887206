.editing-state{
	& > *{
		margin-right: 8px;
	}
	.title-editing{
		height: 32px !important;
		border: 1px #096dd9 solid;
		font-size: medium;
		font-weight: normal;
	}
}

.grey-text{
	color: black;
}
@primary-color: #096dd9;