.assignee{
	display: flex;
	flex-direction: row;
	.origin-assignee{
		display: flex;
		flex-direction: column;
		background-color: #ebebeb;
		padding: 8px;
	}
	.target-assignee{
		display: flex;
		flex-direction: column;
		background-color: #ebebeb;
		padding: 8px;
	}

	.user-name{
		font-weight: bold;
	}

	.org-name{
		color: #616161;
	}

	.pointer{
		align-self: center;
		margin-left: 16px;
		margin-right: 16px;
	}
}

.assignee-container{
	> *{
		margin-top: 8px;
	}
}


@primary-color: #096dd9;