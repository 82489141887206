.statistic-container {
  padding: 16px;

  .count-container {
    display: flex;

    .count-displayer {
      width: 16%;
      margin-left: 8px;
    }
  }
}
@primary-color: #096dd9;