.mailBox-detail {
    width: 100%;
    height: 100%;

    .toolbar {
        .title-with-close {
            text-align: left;
            padding: 8px;
            display: flex;
            flex-direction: row;

            >* {
                margin-right: 8px;
            }

            align-items: baseline;

            >h1 {
                font-size: 24px;
            }
        }

        .contract-info {
            display: flex;
            flex-direction: row;

            .left-part {
                display: flex;
                flex-direction: row;
                padding: 8px;
                width: 50%;
            }

            .right-part {
                display: flex;
                flex-direction: column;
                padding: 8px;
                margin-right: 16px;
                width: 50%;
                text-align: right;

                .opeation>* {
                    margin-left: 8px;
                }

            }
        }

        .contract-detail {
            .sender {
                font-weight: bolder;
            }

            margin-left: 8px;
            text-align: left;
        }
    }

    .detail-content {
        width: 100%;
        height: 98%;

        >iframe {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 8px;

        }
    }
}

.attachment-list {
    .attachment-list-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
@primary-color: #096dd9;