.comment-view-container{
	display: flex;
	flex-direction: column;
	margin: 8px;

	.button-bar{
		display: flex;
		flex-direction: row;
		margin: 8px 0px;
		& > * {
			margin-right: 8px;
		}
	}

}
@primary-color: #096dd9;