.work-card-container {
  .work-count-card-container {
    display: flex;

    .work-count-card {
      margin-left: 8px;
      width: 25%;

      .to-handle-btn {
        color: rgb(65, 48, 159);
      }
    }
  }

  .deadline-container {
    display: flex;

    .deadline-card {
      margin-top: 16px;
      margin-left: 8px;
    }

    .finished-task {
      color: rgb(15, 174, 15);
    }

    .out-of-date-task {
      color: red;
    }

    .processing-task {
      color: rgb(202, 145, 60);
    }

    .deadline-calendar {
      width: 70%;
    }

    .deadline {
      width: 30%;
    }
  }
}
@primary-color: #096dd9;