.approval-form{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	align-content: center;
	margin-left: 16px;
	> *{
		margin-right: 8px;
	}

}
.approval-history-container{
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 8px;
	.approve-title{
		text-align: left;
	}
	.approval-history-table{
		border-left: #aec4fc 2px solid ;
	}

}

.approval-history{
	display: flex;
	flex-direction: row;
	.operator{
		width: 200px;
	}
	.approved {
		width: 200px;
	}
	.message{
		width: auto;
	}
	.date{
		width: 250px;
	}
}
@primary-color: #096dd9;