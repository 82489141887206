.footer-container{
	width: 100%;
	height: 32px;
	padding-top: 4px;
	padding-left: 32px;
	text-align: left;
}

.layout-footer{
	height: 32px;
}
@primary-color: #096dd9;