.attachment-upload{
	margin-top: 16px;
	.drop-box{
		display: flex;
		flex-direction: column;
		& > *{
			margin-top: 8px;
			margin-bottom: 8px;
		}
		.upload-text{
			font-weight: bold;
			font-size: 32px;
		}
		.upload-drag-icon{
			margin-top: 16px;
			height: 48px;
			font-size: 48px;
		}


	}
	.ant-upload-drag-icon{
		height: 64px;
	}

	border-width: 1px;
	border-color: #dfdfdf;
	border-style: dashed;


}
.attachment-upload:hover{
	border-width: 1px;
	border-style: dashed;
	border-color: #08c;
	cursor: pointer;
	color: #08c;
}

.attachment-view{
	display: flex;
	flex-direction: column;
	.fileList{
		margin-top: 16px;
	}
}

@primary-color: #096dd9;