.plugin-config-view{
	display: flex;
	flex-direction: column;
	.plugin-info{
		display: flex;
		flex-direction: column;
		.item {
			display: flex;
			flex-direction: row;
			margin: 8px;
			.label{
				width: 150px;
			}
			.value{
				width: 100%;
				& .red{
					color: red;
				}
			}

		}
	}
}
@primary-color: #096dd9;