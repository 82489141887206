.time-line-view-container{
    margin: 8px;
    display: flex;
    flex-direction: row;
    height: 50px;
    .thread-time-chart{
        width: 100%;
    }
    
}
@primary-color: #096dd9;