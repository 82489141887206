.mail-box-access-editor{

    .control-bar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .actions{
            display: flex;
            flex-direction: row-reverse;
            > *{
                margin-left: 8px;
            }
        }
    }

}
.mail-box-access-list{
    margin-top: 16px;
    .access-item{
        display: flex;
        flex-direction: row;
        padding: 4px;
        .check-box{
            padding-top: 8px;
        }

        > *{
            margin: 4px;
        }
        .name {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 400px;
        }
        .controls{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-switch{
                margin: 4px;
                width: 80px;
            }
            .limit{
                display: flex;
                flex-direction: row;
                align-items: center;
                .label {
                    min-width: 100px;
                }
            
            }
        }
        .save-button {
            margin-left: 16px;
        }

    }

}
@primary-color: #096dd9;