.mail-contacts{
    display: flex;
    flex-direction: column;
    .top-bar{
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .top-bar-title{
            margin-left: 8px;
            text-align: left;
            font-weight: bold;
            font-size: 20px;

        }
        .top-bar-buttons{
            > * {
                margin-right: 16px;    
            }
        }
        margin-bottom: 8px;
        
        border-bottom: 1px #dfdfdf solid;
    }

}
@primary-color: #096dd9;