.dashboard-container{
	margin: 36px;
	.welcome{
		display: flex;
		flex-direction: row;
		align-items: center;
		.welcome-label{
			color: black;
			font-size: large;
			margin-right: 8px;
			margin-left: 8px;
		}
		.welcome-principal{
			color: black;
		}
	}
	.summary-container{
		display: flex;
		flex-direction: row;
		.ticket-list{
			padding: 16px;
			width: 48%;
			.pagination{
				margin-top: 8px;
			}
			.label{
				text-align: left;
				padding: 4px;
				font-size: 24px;
			}
		}
	}
	.ticket-header{
		display: flex;
		flex-direction: column;
		.ticket-subject{
			text-align: left;
			font-weight: bold;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.ticket-subtitle{
			text-align: left;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	.ticket-info{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		> * {
			margin-right: 4px;
		}
	}
	.topbar{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.stats{
			min-width: 160px;
			height: 80px;
			background: #e6f6ff;
			margin: 4px;
			border-radius: 8px;
			.label{
				margin-top: 8px;
			}
			.value{
				font-size: 32px;
				font-weight: bold;
			}
		}
	}

}


@primary-color: #096dd9;