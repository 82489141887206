.email-detail-container {
  .email-info-card {
    border-top: solid 1px #d9d3d3;
    padding: 8px 8px 0 8px;
    margin-bottom: 8px;

    .card-title {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .card-content {
      padding-left: 16px;
    }
  }

  .content-attachment {
    display: flex;
    height: 400px;

    .mail {
      width: 60%;
      border-right: solid 1px #d9d3d3;

      .mail-content {
        height: 100%;
        overflow: auto;
      }
    }

    .attachment {
      width: 40%;
      margin-left: 8px;

      .attachment-list {
        height: 100%;
        overflow: auto;
      }
    }

  }
}



@primary-color: #096dd9;