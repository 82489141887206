.schema-editor{

	margin-right: 16px;
}

.monaco-editor .view-line{
	width: auto !important;
}

.schema-editor > * {
	margin-top: 16px;
}
@primary-color: #096dd9;