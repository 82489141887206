.editor-view {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .no-display {
        display: none !important;
    }

    .quill-editor {
        
        overflow-y: auto;
        //box-shadow: inset 0px 0px 5px 0px #096dd9;
        display: flex;
        height: 100%;
        flex-direction: column;
        .ql-container{
            height: auto;
            border: 0px;
        }
        .tool-bar {
            border-left: 0px;
            border-top: 0px;
            border-right: 0px;
        }
        border: 1px #dfdfdf solid;
    }

    .function-buttons {
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .function-left > * {
            margin-right: 8px;
        }

        .function-right > * {
            margin-left: 8px;
        }
    }
    .mail-form{
        height: 100%;
        flex-direction: column;
        overflow-y:auto;

       

        .mail-meta-item{
            display: flex;
            flex-direction: row;
            margin-top: 8px;
             .col-button{
                .button-detail {
                    width: 100px;
                    margin-right: 8px;
                }
            }
            .col-input {
                width: 100%;
            }
        }
        .mail-attachments{
            .attachment-item{
                display: flex;
                flex-direction: row;
                height: 40px;
                justify-content: space-between;
                margin-top: 8px;
                margin-bottom: 8px;
                border: 1px solid #dfdfdf;
                align-items: center;
                padding-left: 8px;
                padding-right: 8px;
                &:hover {
                    background-color: rgba(9,109,217,0.1);
                    cursor: pointer;
                }
            }
            height: 100%;
            margin-top: 24px;

            .list-header{
                text-align: left;
                display: flex;
                flex-direction: row;
            }
        }
        .mail-meta-description{
            display: flex;
            flex-direction: column;
            text-align: left;

            .description-label {
                font-size: small;
                margin-top: 8px;
            }

        }
    }

}

.width-100 {
    width: 100%;
}

.template-selector {
    width: 600px;
}
@primary-color: #096dd9;