.template-panel {
  .ant-collapse-content-box {
    display: flex;
    justify-content: center;

    .template-container {
      white-space: pre-wrap;
      text-align: left;
      width: auto;
    }
  }
}

.button {
  margin-right: 8px;
}
@primary-color: #096dd9;