.process-initial-editor{
	.initial-button{
		text-align: left;
		margin-left: 16px;
	}
	display: flex;
	flex-direction: column;
	.process-list{
		margin-left: 16px;
		.editor-item{
			display: flex;
			flex-direction: row;
			> *{
				margin-right: 8px;
			}
			.label {
				width: 100px;
				padding-top: 4px;
			}
		}
	}
}
@primary-color: #096dd9;