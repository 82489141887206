.tag-container {
	padding: 16px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.tags {
		display: flex;
		flex-direction: row;
		div {
			margin-left: 16px;
		}
	}
	.control{
		display: flex;
		flex-direction: row;
		> * {
			margin: 8px;
			margin-bottom: 16px;
		}
	}
	.tooltip{
		> div{
			padding: 4px;
		}
	}
	.tag-display{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0px;
		margin-top: 16px;
		> div {
			margin: 0px;
			margin-bottom: 8px;
		}
	}
}
@primary-color: #096dd9;