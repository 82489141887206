.description-view-container{
	display: flex;
	flex-direction: column;

	.description-control-bar{
		display: flex;
		flex-direction: row-reverse;
		padding-right: 16px;
	}

	.description-control-bottom-bar{
		display: flex;
		flex-direction: row-reverse;
		padding-right: 16px;
		margin-top: 32px;
	}
	.control-panel{
		display: flex;
		flex-direction: row;
		margin-bottom: 8px;
		& > *{
			margin-right: 8px;
		}
	}
	.editor-container{
		display: flex;
		flex-direction: row;
		height: 100%;
		.detail-view-editor{
			width: 100%;
			height: 400px;
		}
		.detail-preview{
			width: 100%;
			text-align: left;
			padding: 4px;
			border-width: 1px;
			border-color: #0c0c0c;
			border-style: dashed;
		}
	}
}	
@primary-color: #096dd9;