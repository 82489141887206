
.history-summary-view{
    display: flex;
    flex-direction: row;
    min-height: 160px;
    .summary-chart{
        width: 20%;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }
    .history-chart{
        width: 80%;
        display: flex;

    }
}
@primary-color: #096dd9;