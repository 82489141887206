.setting-box-container{
	display: flex;
	flex-direction: column;
	.title{
		margin-top: 8px;
		text-align: left;
	}
	.box {
		margin-top: 8px;
		display: flex;
		.button {
			margin-left: 8px;
		}
	}
	.key{
		margin-bottom: 8px;
		text-align: left;
		padding-left: 4px;
		color: #dfdfdf;
	}
}
@primary-color: #096dd9;