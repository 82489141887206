.time-shift-editor{
	display: flex;
	flex-direction: column;
	.escalation-item{
		display: flex;
		flex-direction: row;
		width: 100%;
		.escalation-condition{
			display: flex;
			flex-direction: row;
			.condition-item{
				display: flex;
				flex-direction: row;
				margin:0px 4px;
				.label{
					padding-top: 4px;
					margin: 0px 4px;
				}

			}
			
		}
		.escalation-target{
			width: 50%;
		}
	}
	.time-limited{
		display: flex;
		flex-direction: row;
		align-items: center;
		& > * {
			margin-right: 8px;
		}
	}
}
@primary-color: #096dd9;