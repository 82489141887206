.mail-template-container {
    .operation-bar {
        display: flex;

        >* {
            margin-left: 8px;
            margin-bottom: 8px;
        }

    }
}
@primary-color: #096dd9;