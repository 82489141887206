.vendor-viewer{
    .ant-descriptions-title{
        text-align: left;
        padding-left: 8px;
    }
    .description-view{
        
    }
    .combied-mail-boxes{
        margin-top: 17px;

    }
}
@primary-color: #096dd9;