.access-control-landingpage{
	display: flex;
	height: 100%;
	width: 100%;
	background: url("../../images/landing.svg");
	background-repeat: no-repeat;
	background-position: center;
	opacity:0.5;
	background-size:50% 50%;
	flex-direction: column;
	.description{
		margin-top: 8vh;
		font-size: 2rem;
	}
}
@primary-color: #096dd9;