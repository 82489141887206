.ticket-search{
	display: flex;
	flex-direction: row;
	.field{
		align-self: center;
		margin-right: 4px;
		display: flex;
		flex: 1 1 0;
		flex-direction: row;
		.field-title{
			text-overflow: ellipsis;
			white-space:nowrap;
			padding: 8px;
		}
		.selector {
			width: 100%;
		}
	}
	.search-bar {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: right;
		
		.button {
			margin-left: 8px;
		}
	}
}
@primary-color: #096dd9;