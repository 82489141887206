.boxes-conf-container{
    padding: 16px;
    .function-bar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left{ 
            display: flex;
            > * {
                margin-right: 8px;
            }
            margin-left: 8px;
        }
        .right {
            display: flex;
            > * {
                margin-left: 8px;
            }
        }
        
    }
}   
@primary-color: #096dd9;