.participator-analysis-container{
    display: flex;
    flex-direction: column;
    margin: 16px;
    .summary{
        display: flex;
        flex-direction: row;
        .pie-block {
            width: 240px;
        }
    }

}
@primary-color: #096dd9;