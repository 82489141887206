.project-market {
  .operation-bar {
    display: flex;
    padding: 16px;

    >* {
      margin-right: 4px;
    }
  }
}

.padding-16px {
  padding: 16px;
}
@primary-color: #096dd9;