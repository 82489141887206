.efficency-container {
    display: flex;
    flex-direction: column;
    margin:16px;
    height: 100%;


    .sequence-container {
        width: 100%;
        padding: 8px;
        display: flex;
        flex-direction: column;
    }

    .percentage-container{
        width: 100%;
        display: flex;
        flex-direction: row;

        .time-cost-container {
            width: 50%;
            margin: 16px;
        }
        .time-percent-container {
            width: 50%;
            margin: 16px;
        }
    }

    h1{
        margin-top: 16px;
        margin-bottom: 16px;
        margin-right: 16px;
        text-align: left;
        border-bottom: 1px solid #dfdfdf;
    }
}
@primary-color: #096dd9;