.frequency-selector {

    .title {
        text-align: left;
    }

    .frequency-editor {
        margin-top: 16px;
        .field {
            margin: 8px 0px;
            justify-content: left;
            text-align: left;
        }

        .flat-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
@primary-color: #096dd9;