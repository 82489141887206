.new-form-editor{
	& > * {
		margin:8px 0;
	}
	.selector{
		
		> button{
			margin-left: 8px;
		}
	}
}
@primary-color: #096dd9;