.text-field-editor {
    & > *{
        margin-right: 4px;
    }
}
.editable-field{
    &:hover {
        color: #096dd9;
        cursor: pointer;
    }
}
@primary-color: #096dd9;