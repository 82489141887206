.escalation-definition-container{
    margin: 16px;
    .rules-content{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
    }
    .ticket-filter{
        width: 24%;
    }
    .ticket-axis{
        width: 24%;
    }
    .spliter{
        margin-left: 8px;
        margin-right: 8px;
        align-self: center;
    }
    .ticket-frequency-selctor{
        width: 24%;
    }

    .ticket-action-selector{
        width: 24%;
    }
    
}

.field{
    margin: 8px 0px;    
}


.ant-collapse-extra{
    > *{
        margin-left: 4px;
    }
}
@primary-color: #096dd9;