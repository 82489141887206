.reload-operation-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 24px;

  .ant-input {
    width: 800px;
  }
}
@primary-color: #096dd9;