.assign-to-panel{
    display: flex;
    flex-direction: column;
    
    .fields {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        .title {
            width: 100px;
        }
    }
    .button-bar{
        > *{
            margin-right: 8px;
        }
    }
}

.user-active{
	color: #000;
}
.user-inactive{
	color: #dfdfdf;
}
@primary-color: #096dd9;